import * as R from 'ramda'
import React from 'react'
import { TextField } from '@material-ui/core'
import Swal from 'sweetalert2'

import { notNilOrEmpty } from '../lib/Helpers'

export default props => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: ''
  })

  function _handleInputChange(e) {
    e.preventDefault()
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  function _handleForm(e) {
    e.preventDefault()

    notNilOrEmpty(props.token)
      ? fetch('/api/contact', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            message: formData.message,
            exhibitorEmail: props.exhibitorEmail,
            exhibitorContact: props.exhibitorContact
          })
        }).then(res => {
          if (res.status >= 400) {
            return Swal.fire({
              icon: 'error',
              title: 'Oops... Something went wrong!',
              text: 'There has been an error. Please try again.',
              customClass: {
                confirmButton: 'btn btn--black btn--confirm'
              },
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            })
          } else {
            setFormData({
              name: '',
              email: '',
              message: ''
            })

            return Swal.fire({
              icon: 'success',
              title: 'Thank you for your message.',
              text: 'We will be in touch soon.',
              customClass: {
                confirmButton: 'btn btn--black btn--confirm'
              },
              showClass: {
                popup: 'animated fadeInDown faster'
              },
              hideClass: {
                popup: 'animated fadeOutUp faster'
              }
            })
          }
        })
      : props.setNoCaptchaToken('Could not verify Captcha Token')
  }

  return (
    <form
      method="post"
      className="exhibitor-contact-form"
      action="/"
      onSubmit={_handleForm}
    >
      <TextField
        className="input"
        type="text"
        name="name"
        id="name"
        label="Your Name"
        onChange={_handleInputChange}
        value={formData.name}
        required
        helperText="3 Characters Minimum"
      />
      <TextField
        className="input"
        type="email"
        name="email"
        id="email"
        label="Your email"
        onChange={_handleInputChange}
        value={formData.email}
        required
      />
      <TextField
        className="textarea"
        name="message"
        id="message"
        label="Your Message"
        multiline
        rows={4}
        onChange={_handleInputChange}
        value={formData.message}
        required
        helperText="10 Characters Minimum"
      />
      <div className="submit-button">
        <button
          className="btn btn--black btn-confirm"
          type="submit"
          disabled={
            notNilOrEmpty(props.token) &&
            notNilOrEmpty(formData.name) &&
            R.gt(formData.name.length, 3) &&
            notNilOrEmpty(formData.email) &&
            notNilOrEmpty(formData.message) &&
            R.gt(formData.message.length, 10)
              ? false
              : true
          }
        >
          Send
        </button>
      </div>
    </form>
  )
}
