import React from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

// import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

export default props => {
  const [numPages, setNumPages] = React.useState(null)
  const [pageNumber, setPageNumber] = React.useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function changePage(offset) {
    setPageNumber(pageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <>
      <Document file={props.asset.url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
        <div className="page-controls">
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            ‹
          </button>
          <span>
            {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </span>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            ›
          </button>
        </div>
      </Document>
      {props.lightbox ? (
        <a onClick={props.lightbox}>View Larger</a>
      ) : (
        <a
          target="_blank"
          rel="noopener"
          href={props.asset.url}
          style={{ color: 'white ' }}
        >
          Download PDF
        </a>
      )}
    </>
  )
}
